<template>
    <div>
        <CustomTable
            ref="season_mare_table"
            id_table="season_mare_stallion"
            :items="filtredSeasonMareStallion"
            :busy.sync="table_busy"
            primaryKey="seasonmarestallion_id"
            :hide_if_empty="true"
			:hrefsRoutes="config_table_hrefs"
            :externSlotColumns="['seasonmarestallion_default_format']"
        >
        <template v-slot:[`custom-slot-cell(seasonmarestallion_default_format)`]="{ data }">
            <div v-if="!data.porteuse_id || data.porteuse_id === seasonMare.seasonmare_horse" class="custom-control custom-checkbox">
                <b-form-checkbox 
                    name="check-button"
                    switch 
                    v-model="data.seasonmarestallion_default"
                    @change="switchDefault(data.seasonmarestallion_id, data.seasonmarestallion_default, data.horse_id)"
                >
                    <template v-if="data.seasonmarestallion_default">
                        {{ $t('global.oui') }}
                    </template>
                    <template v-else>
                        {{ $t('global.non') }}
                    </template>
                </b-form-checkbox>
            </div>
        </template>
        </CustomTable>

        <b-modal ref="modal" hide-footer size="xl">
            <template v-slot:modal-title>
                <template v-if="ajouter">
                    {{ $t("gynecologie.ajouter_season_mare_stallion") }}
                </template>

                <!-- <template v-if="modifier">
                    {{ $t("gynecologie.modifier_season_mare_stallion") }}
                </template> -->
            </template>

            <SeasonMareStallionAjout 
                :add-or-edit-ready.sync="addOrEditReady"
                ref="seasonMareStallionAjout"
                :seasonmarestallion_id="seasonmarestallion_id"
                :season-mare-stallion="seasonMareStallion"
                :season-mare="seasonMare"
            />
            <div class="text-center">
            <b-button v-if="ajouter && addOrEditReady"  pill variant="primary" class="mt-3"  @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
            <!-- <b-button v-if="modifier && addOrEditReady"  pill variant="primary" class="mt-3"  @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.modifier") }}</b-button> -->
            </div>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js"
    import Shutter from "@/mixins/Shutter.js"
    import GynecologieMixin from "@/mixins/Gynecologie.js"
    import Common from '@/assets/js/common.js'

    export default {
        name: "GynecologieHorseHistoriqueSaisonEtalon",
        mixins: [TableAction, Navigation, Shutter, GynecologieMixin],
        props: {
            season_id: { type: Number, default: null }, 
            horse_id: { type: Number, default: null }, 
            goToAddHorseStallion: { type: Boolean, default: false }
        },
        data () {
            return {
                addOrEditReady: false,
                processing: false,
                ajouter: false,
                // modifier: false,
                seasonmarestallion_id: null,
                seasonMare: {},
                seasonMareStallion: null,
				table_busy: true,
				config_table_hrefs: {
                    'horse_nom': {
						routeUniqueName: 'EtalonFiche',
						routeName: 'HorseFicheInfo',
                        params: {
                            horse_id: 'horse_id'
                        }
					},
					'mare_nom': {
						routeUniqueName: 'MareFiche',
						routeName: 'HorseFicheInfo',
						params: {
							horse_id: 'mare_id'
						}
					},
					'porteuse_nom': {
						routeUniqueName: 'PorteuseFiche',
						routeName: 'HorseFicheInfo',
						params: {
							horse_id: 'porteuse_id'
						}
					}
				},
                events_tab: {
                    'TableAction::goToAddSeasonMareStallion': () => {
                        this.addOrEdit()
                    },
                    // 'TableAction::goToEditSeasonMareStallion': (params) => {
                    //     this.addOrEdit(params.seasonmarestallion_id)
                    // },
                    'TableAction::goToDeleteSeasonMareStallion': (params) => {
                        this.deleteSeasonMare(params)
                    },
                    'TableAction::goToArchiveCouple': (params) => {
                        this.goToArchiveCouple(params)
                    }
                }
            }
        },
        created() {
            this.init_component()
        },
        methods: {
            async init_component() {
                if(!this.horse_id || !this.season_id) return

                this.table_busy = true
				let seasonMareStallions = await this.loadSeasonMareStallionBySeasonHorse(this.horse_id, this.season_id)

                if(seasonMareStallions) {
                    seasonMareStallions = seasonMareStallions[0]
                    this.seasonMare = seasonMareStallions
					
					if(seasonMareStallions.seasonmarestallion) {
                        this.seasonMareStallion = []
                        seasonMareStallions.seasonmarestallion.forEach((seasonMareStallion) => {
							if(!seasonMareStallion.horse
								|| !seasonMareStallion.mare
								|| !seasonMareStallion.mare.horse
							) {
								return
							}

                            let temp = {}
                            temp.horse_nom = seasonMareStallion.horse.horse_nom
                            temp.horse_id = seasonMareStallion.horse.horse_id
							temp.mare_nom = seasonMareStallion.mare.horse.horse_nom
							temp.mare_id = seasonMareStallion.mare.horse.horse_id
							temp.porteuse_nom = seasonMareStallion.porteuse ? seasonMareStallion.porteuse.horse.horse_nom : null
							temp.porteuse_id = seasonMareStallion.porteuse ? seasonMareStallion.porteuse.horse.horse_id : null
                            temp.seasonmarestallion_id = seasonMareStallion.seasonmarestallion_id
                            temp.seasonmarestallion_idsort = seasonMareStallion.seasonmarestallion_id_absolute
                            temp.seasonmarestallion_dps = seasonMareStallion.seasonmarestallion_dps
                            temp.seasonmarestallion_dps_format = seasonMareStallion.seasonmarestallion_num_dps
                            temp.seasonmarestallion_default = seasonMareStallion.seasonmarestallion_default == 1 ? true : false
							temp.seasonmarestallion_default_format = this.getTrad("global.non")
							temp.seasonmarestallion_status = Common.translateSeasonMareStatus(seasonMareStallion.seasonmarestallion_status)
                            temp.seasonmarestallion_archive = seasonMareStallion.seasonmarestallion_archive
                            temp.seasonmarestallion_archive_format = this.getTrad("global.non")

                            if(seasonMareStallion.seasonmarestallion_default) {
                                temp.seasonmarestallion_default_format = this.getTrad("global.oui")
							}
                            if(seasonMareStallion.seasonmarestallion_archive) {
                                temp.seasonmarestallion_archive_format = this.getTrad("global.oui")
                            }
                            this.seasonMareStallion.push(temp)
                        })
                    } else {
                        this.seasonMareStallion = null
                    }
                } else {
                    this.seasonMareStallion = null
                }

                this.table_busy = false

                if(this.goToAddHorseStallion) {
                    this.addOrEdit()
                }
            },
            async addOrEdit(seasonmarestallion_id) {
                this.processing = false
                this.addOrEditReady = false
                // if(seasonmarestallion_id) {
                //     this.ajouter = false
                //     this.modifier = true
                //     this.seasonmarestallion_id = seasonmarestallion_id
                // } else {
                //     this.ajouter = true
                //     this.modifier = false
                //     this.seasonmarestallion_id = null
                // }
                this.ajouter = true
                // this.modifier = false
                this.seasonmarestallion_id = null

                this.$refs.modal.show()
            },
            async switchDefault(seasonmarestallion_id, seasonmarestallion_default, horse_id) {
                seasonmarestallion_default = !seasonmarestallion_default
                const result = await this.editSeasonMareStallion(
                seasonmarestallion_id,
                {
                    "seasonmarestallion_horse": horse_id,
                    "seasonmarestallion_default": seasonmarestallion_default,
                    "seasonmarestallion_archive": false, // désarchive si sélectionné par défaut
                })
                const otherMareStallions = this.filtredSeasonMareStallion.filter((couple) => seasonmarestallion_id != couple.seasonmarestallion_id);
                seasonmarestallion_default ? this.switchAllSeasonMareStallions(otherMareStallions, false) : null;
            },
            async switchAllSeasonMareStallions(otherMareStallions, bool){
                const result = await Promise.all(otherMareStallions.map(async (seasonMareStallion) => {
                    // On edite les default dans la indexedDB
                    const process = await this.editSeasonMareStallion(
                        seasonMareStallion.seasonmarestallion_id,
                        {
                            "seasonmarestallion_horse": seasonMareStallion.horse_id,
                            "seasonmarestallion_default": bool
                        })

                    // On doit aussi editer ce qu'on voit en live
                    seasonMareStallion.seasonmarestallion_default = bool
                    return seasonMareStallion
                }))
                
                this.init_component();
            },
            async checkForm() {
                if(!this.processing) {
                    this.processing = true
                    let result = await this.$refs.seasonMareStallionAjout.checkForm()
                    if(result) {
                        this.$refs.modal.hide()
                        EventBus.$emit('GynecologieHorse::reload', true)
                    } else {
                        this.processing = false
                    }
                }
            },

            async deleteSeasonMare(seasonMareStallion) {
                let tab_promises = []
                let failed_to_delete_all_couples = false
                seasonMareStallion.forEach(seasonMare => {
                    tab_promises.push(new Promise(async (res, _) => {
                        let delete_couple = await this.deleteSeasonMareStallion(seasonMare.seasonmarestallion_id, true)
                        failed_to_delete_all_couples = (delete_couple == false) // Si au moins un couple n'a pas pu être supprimé
                        return res()
                    }))
                })

                Promise.all(tab_promises)
                .then(() => {
                    if (failed_to_delete_all_couples) {
                        this.failureToast('toast.info_couples_not_deleted')
                    } else {
                        this.successToast('toast.info_save_succes')
                    }
					EventBus.$emit('GynecologieHorse::reload', true)
                })
                .catch(() => {
                    this.failureToast('toast.info_save_failed')
                })
            },

            async goToArchiveCouple(sms) {
                await this.archiveOrUnarchiveCouple(sms)
                this.$refs.season_mare_table.unselectAll()
                this.init_component()
            }
        },
        computed: {
            filtredSeasonMareStallion: function() {
                return this.seasonMareStallion
            }
        },
        watch: {
            season_id() {
                this.init_component()
            },
            horse_id() {
                this.init_component()
            }
        },
        components: {
			HorseAjout: () => import("@/components/Tiers/HorseAjout"),
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            SeasonMareStallionAjout : () => import('@/components/Gynecologie/SeasonMareStallionAjout'),
        },
    }
</script>
